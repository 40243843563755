.btn-calendar {
  border: 1px solid #999;
}
time.icon
{
  font-size: 0.8em; /* change icon size */
  display: block;
  position: relative;
  width: 100%;
  height: 8em;
  background-color: #fff;
  /* margin: 0.75em auto; */
  border-radius: 0.2em;
  /* box-shadow: 0 2px 0 #bdbdbd; */
  overflow: hidden;
}

time.icon *
{
  display: block;
  width: 100%;
  font-size: 1em;
  font-weight: bold;
  font-style: normal;
  text-align: center;
}

time.icon strong
{
  position: absolute;
  top: 0;
  padding: 0.4em 0;
  color: #fff;
  background-color: #98cbe5;
  border-bottom: 1px dashed #98cbe5;
  /* box-shadow: 0 2px 0 #98cbe5; */
}

time.icon strong.day
{
  position: absolute;
  top: 0;
  padding: 0.4em 0;
  color: #fff;
  background-color: #ff0000;
  border-bottom: 1px dashed #ff0000;
  /* box-shadow: 0 2px 0 #ff0000; */
}
time.icon em
{
  position: absolute;
  /* bottom: 0.3em; */
  color: #2f2f2f;
}

time.icon span
{
  width: 100%;
  font-size: 2.8em;
  letter-spacing: -0.05em;
  padding-top: 0.8em;
  color: #2f2f2f;
}

