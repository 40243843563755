.main-sidebar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.main-sidebar ul li {
  text-decoration: none;
  background: #77c2fa;
}

.main-sidebar ul li a:hover, .active {
  text-decoration: none;
  background: #5196d3;
}

.version {
  text-emphasis-color: lightblue;
}
