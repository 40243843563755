.bg-request {
  background-color: #FFD9AC;
}
.bg-job {
  background-color: #FFD9AC;
}
.bg-complete {
  background-color: #dde3e9;
}

.list-group {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 200px;
  height: 100%;
  background-color: #9c9595;
  position: fixed;
  overflow: y;
  font-size: 12pt
}

li a {
  display: block;
  color: black;
  padding: 8px 16px;
  text-decoration: none;
  text-align: left;
  font-size: 12pt
}

li a:hover {
  background-color: #555;
  color: #000000;
}

.container {
  width: 100%;
  padding-right: 5px;
  padding-left: 5px;
  margin-right: auto;
  margin-left: auto;
  overflow: hidden;
}

.corners {
  border-radius: 10px;
}

.buttons {
  border : 0;
  padding : 0.5rem 0.7rem;
  border-radius: 5px;
}

.jobs {
  border-left: 6px solid orange;
  height : 100%;
}