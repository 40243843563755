@media (max-width: 575.98px) {
  .bg-sidebar {
    position: relative !important;
  }
  .wrapper {
    margin-left: 0!important;
  }
  .p5-lg {
    padding: 0!important;
  }
}

/* // Small devices (landscape phones, 576px and up) */
@media (max-width: 900px) {
  .bg-sidebar {
    position: relative !important;
  }
  .wrapper {
    margin-left: 0!important;
  }
  .p5-lg {
    padding: 1rem!important;
  }
}

body {
  background-color: #fafafa;
  /* height: auto; */
  /* min-height: 100%; */
}
.p5-lg {
  padding: 3rem;
}
/* .main-sidebar */
.bg-sidebar {
  position: absolute;
  background-color: #43425d;
  height: 100%;
  min-height: 100%;
}
.bg-menu {
  background-color: #43425d!important;
  color: #fff;
}

.wrapper {
  margin-left: 16.666667%;
  padding-left: auto;
  padding-right: auto;
}

.list-group-item.active {
  background-color: #dde3e9;
  color: #000000;
  border-color: #dde3e9;
}

.btn-circle {
  width: 60px;
  height: 60px;
  line-height: 45px;
  text-align: center;
  padding: 0;
  border-radius: 50%;
}

.btn-circle i {
  position: relative;
  top: -1px;
}

.btn-circle-sm {
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: 0.9rem;
}

.btn-circle-lg {
  width: 55px;
  height: 55px;
  line-height: 55px;
  font-size: 1.1rem;
}

.btn-circle-xl {
  width: 70px;
  height: 70px;
  line-height: 70px;
  font-size: 1.3rem;
}

.bold {
  font-weight: 700!important;
}

.btn-primary-green {
  background-color: rgb(123, 199, 134);
}

.btn-primary-red {
  background-color: rgb(236, 55, 50);
}

.page-name {
  font-size: 1.3rem;
  padding: 0.8rem;
  /* ackground-color: #ff9416; */
  text-align: center;
  /* text-transform: uppercase; */
  font-weight: 700!important;
}

.connecting-line {
  height: 2px;
  background: #28a745;
  position: relative;
  width: 80%;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 50%;
  z-index: 1;
}
.Nav__navBar___xtCFA {
  display: none;
}
[data-test="authenticator-error"] {
  position: fixed;
  background-color: #dc3545!important;
}

.line-100 {
  width: 100%;
  border-bottom: 1px solid #6c757d;
  margin-top: -55px;
}

.line-50-right {
  width: 50%;
  border-bottom: 1px solid #6c757d;
  margin-top: -55px;
  margin-left: 50%;
}

.line-50-left {
  width: 50%;
  border-bottom: 1px solid #6c757d;
  margin-top: -55px;
  margin-right: 50%;
}

.react-datepicker-wrapper {
  display: block;
}
/* TIMELINE INLINE */
.timeline {
  /* set a variable for the color */
  --timeline-color: #3fb2be;
  position: relative;
  list-style: none;
  display: inline-flex;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0;
  height: 4rem;
}  /* set here the height of the timeline */


/* middle line */
.timeline:before {
  content: "";
  position: absolute;
  top: calc(50% - 1px);
  width: 100%;
  height: 2px;
  background: var(--timeline-color); }


.timeline li {
  margin: 0 5px;
  min-width: 100px;
  text-align: left;
  font-weight: 700!important;
  font-size: 0.9rem;
  /* align-self: flex-start; */
  /* align-content: center; */
  position: relative;
}


/* style for the dot over the timeline */
.timeline li.cicle-active:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  /* left: translate(-50%, -50%); */
  transform: translate(-50%, -50%);
  border: 2px #3fb2be solid;
  width: 10px;
  height: 10px;
  padding: .5rem;
  border-radius: 50%;
  background:  var(--timeline-color);
}

.timeline li:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  /* left: translate(-50%, -50%); */
  transform: translate(-50%, -50%);
  border: 2px #3fb2be solid;
  width: 10px;
  height: 10px;
  padding: .5rem;
  border-radius: 50%;
  background:  #fff;
}

.downshift-dropdown {
  margin: 0 auto;
  width: 20rem;
  border: 1px solid whitesmoke;
  border-bottom: none;
}
.dropdown-item {
  padding: 0.5rem;
  cursor: pointer;
  border-bottom: 1px solid whitesmoke;
  font-size: 1rem;
  text-align: left;
}
.dropdown-button {
  padding: 0.6rem;
  border-radius: 3px;
  background: white;
  cursor: pointer;
}
.m-signature-pad {
  position: absolute;
  font-size: 10px;
  width: 700px;
  height: 400px;
  top: 50%;
  left: 50%;
  margin-left: -350px;
  margin-top: -200px;
  border: 1px solid #e8e8e8;
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.27), 0 0 40px rgba(0, 0, 0, 0.08) inset;
  border-radius: 4px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.m-signature-pad--body {
  position: absolute;
  left: 20px;
  right: 20px;
  top: 20px;
  bottom: 60px;
  border: 1px solid #f4f4f4;
}

.m-signature-pad--body
  canvas {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.02) inset;
  }

.m-signature-pad--footer {
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 20px;
  height: 40px;
}

.m-signature-pad--footer
  .description {
    color: #C3C3C3;
    text-align: center;
    font-size: 1.2em;
    margin-top: 1.8em;
  }

.m-signature-pad--footer
  .button {
    position: absolute;
    bottom: 0;
  }

.m-signature-pad--footer
  .button.clear {
    left: 0;
  }

.m-signature-pad--footer
  .button.save {
    right: 0;
  }

@media screen and (max-width: 1024px) {
  .m-signature-pad {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    min-width: 250px;
    min-height: 140px;
    margin: 5%;
  }
  #github {
    display: none;
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .m-signature-pad {
    margin: 10%;
  }
}

@media screen and (max-height: 320px) {
  .m-signature-pad--body {
    left: 0;
    right: 0;
    top: 0;
    bottom: 32px;
  }
  .m-signature-pad--footer {
    left: 20px;
    right: 20px;
    bottom: 4px;
    height: 28px;
  }
  .m-signature-pad--footer
    .description {
      font-size: 1em;
      margin-top: 1em;
    }
}